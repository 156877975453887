
import { defineComponent } from "vue";
import MainButton from "@/components/atoms/buttons/MainButton.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default defineComponent({
  name: "CreateNewInviteMHTSection",
  data() {
    const schema = yup.object().shape({
      title: yup.string().required("Введите")
    });

    return {
      isModalOpen: false,
      schema,
    };
  },
  components: {
    MainButton,
    Form,
    Field,
    ErrorMessage,
  },
  mounted() {},
  methods: {
    createInvite() {
      console.log('send')
      this.$router.push('/admin/get-mht')
    }
  },
});
