
import { defineComponent } from 'vue'
import AdminLayout from '@/templates/AdminLayout.vue'
import CreateNewInviteMHTSection from '@/components/sections/admin/CreateNewInviteMHTSection.vue'

export default defineComponent({
  name: 'CreateNewInviteMHT',
  components: {
    AdminLayout,
    CreateNewInviteMHTSection
  },
  mounted() {
  }
})
